.root {
  --trending-card--actions-top-margin: calc(var(--g--card-gap) / 2);
  --trending-card--edge-padding: var(--g--card-gap);
  --trending-card--border-radius: var(--g--card-border-radius);
  --trending-card--gap: var(--g--card-gap);
  --trending-card--action-gap: var(--g--card-action-gap);
  --trending-card--action-height: var(--g--card-action-height);
  --trending-card--width: var(--g--card-portrait-large-width);
  --trending-card--height: var(--g--card-portrait-large-height);
  --trending-card--title-height: clamp(40px, calc(0.169 * var(--trending-card--width)), 60px);
  --trending-card--container-width: calc(
    var(--trending-card--width) + 2 * var(--trending-card--edge-padding)
  );
  --trending-card--container-height: calc(
    var(--trending-card--height) + var(--trending-card--title-height) +
      var(--trending-card--edge-padding)
  );
  --trending-card--width-unitless: var(--g--card-portrait-large-width-unitless);
  --trending-card--active-scale-factor: calc(
    (var(--trending-card--width-unitless) + 16) / var(--trending-card--width-unitless)
  );

  pointer-events: none;
  content-visibility: auto;
  contain-intrinsic-size: var(--trending-card--container-width)
    var(--trending-card--container-height);

  position: relative;

  contain: strict;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  width: var(--trending-card--container-width);
  height: var(--trending-card--container-height);
  margin: calc(var(--trending-card--edge-padding) * -1);
  margin-bottom: 0;

  &.isActive,
  &.isAnimatingOut {
    content-visibility: visible;
    contain: layout;
  }
}

.scrollTarget {
  position: absolute;
  inset: var(--trending-card--edge-padding) var(--trending-card--edge-padding) 0;
  visibility: hidden;
}

.title {
  pointer-events: auto;
  contain-intrinsic-size: var(--trending-card--width) var(--trending-card--title-height);

  position: absolute;
  bottom: 0;
  left: var(--trending-card--edge-padding);

  contain: strict;
  display: flex;
  gap: var(--g--margin-sm);
  align-items: center;
  justify-content: flex-start;

  width: var(--trending-card--width);
  height: var(--trending-card--title-height);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    opacity: 0;
    transition-duration: calc(var(--g--anim-duration) / 2);
  }
}

.titleText {
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;

  font-size: clamp(14px, calc(0.056 * var(--trending-card--width)), 20px);
  font-weight: 500;
  line-height: 1.2;
  color: var(--g--color-grey);
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
}

.titleRank {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  height: 100%;

  font-family: var(--g--font-mono);
  font-size: clamp(60px, calc(0.256 * var(--trending-card--width)), 91px);
  line-height: 0.7;
  color: color-mix(in srgb, var(--g--color-light-sky), transparent 80%);
  letter-spacing: -0.075em;
}

.imageContainer {
  position: absolute;
  top: var(--trending-card--edge-padding);
  left: var(--trending-card--edge-padding);
  transform: scale(1);

  contain: layout style;

  width: var(--trending-card--width);
  height: var(--trending-card--height);

  border-radius: var(--trending-card--border-radius);
  box-shadow: var(--g--shadow-md);

  transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  .isActive & {
    transform: scale(var(--trending-card--active-scale-factor));
  }
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(var(--trending-card--action-height)) scale(0.9);

  display: flex;
  gap: var(--trending-card--action-gap);

  width: 100%;
  height: calc(var(--trending-card--action-height) + var(--trending-card--actions-top-margin));
  padding-top: var(--trending-card--actions-top-margin);

  opacity: 0;

  transition:
    opacity var(--g--anim-duration) var(--g--anim-easing),
    transform var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    pointer-events: auto;
    transform: translateY(100%) scale(1);
    opacity: 1;
    transition:
      opacity calc(var(--g--anim-duration) * 1.5) var(--g--anim-easing),
      transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);
  }
}

.launchAction {
  flex-grow: 1;
  flex-shrink: 1;
}

.infoAction {
  flex-grow: 0;
  flex-shrink: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: var(--trending-card--border-radius);
}

.category {
  position: absolute;
  top: 6px;
  right: 6px;

  overflow: hidden;
  display: inline-block;

  max-width: calc(100% - 12px);

  color: var(--g--color-light-grey);
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  background-color: rgb(0 0 0 / 40%);

  html[data-nav-device='mouse'] &.category:hover,
  html[data-nav-device='keyboard'] &.category:focus {
    color: var(--g--color-white);
  }
}

.logoContainer {
  position: absolute;
  top: 17.5%;
  left: 17.5%;

  display: block;

  width: 65%;
  height: 65%;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center center;
}

.activeTitle {
  position: absolute;
  bottom: 6px;
  left: 6px;

  overflow: hidden;
  display: block;

  max-width: calc(100% - 12px);
  height: 26px;
  padding: 0 6px;

  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: var(--g--color-white);
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 0;
  background: var(--g--glass-dark-100);
  backdrop-filter: blur(20px);
  border-radius: var(--g--border-radius-sm);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    opacity: 1;
  }
}

.imageLink {
  pointer-events: auto;

  position: absolute;
  inset: 0;

  color: transparent;

  background: transparent;
  border-radius: var(--trending-card--border-radius);
  outline: none;
  box-shadow: var(--g--inset-highlight-md);
}
