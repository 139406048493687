.root {
  pointer-events: none;

  position: absolute;
  inset: 0;

  overflow: hidden;

  opacity: 0;

  transition: opacity 0.6s ease-in-out;

  &[data-active='true'] {
    pointer-events: auto;
    opacity: 1;
  }
}

.bgImage {
  pointer-events: none;

  position: absolute;
  inset: 0;

  object-fit: cover;
  object-position: center center;
}

.video {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  opacity: 0;
  object-fit: cover;
  object-position: center center;

  transition: opacity 0.6s ease-in-out;

  &[data-active='true'] {
    opacity: 1;
  }
}

.sideFade {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  background-image: linear-gradient(
    -90deg,
    color-mix(in srgb, var(--g--color-black), transparent 100%) 49.02%,
    color-mix(in srgb, var(--g--color-black), transparent 99.2%) 52.42%,
    color-mix(in srgb, var(--g--color-black), transparent 96.5%) 55.82%,
    color-mix(in srgb, var(--g--color-black), transparent 91.8%) 59.22%,
    color-mix(in srgb, var(--g--color-black), transparent 85%) 62.62%,
    color-mix(in srgb, var(--g--color-black), transparent 77%) 66.02%,
    color-mix(in srgb, var(--g--color-black), transparent 66.7%) 69.41%,
    color-mix(in srgb, var(--g--color-black), transparent 55.7%) 72.81%,
    color-mix(in srgb, var(--g--color-black), transparent 44.3%) 76.21%,
    color-mix(in srgb, var(--g--color-black), transparent 33.3%) 79.61%,
    color-mix(in srgb, var(--g--color-black), transparent 23%) 83.01%,
    color-mix(in srgb, var(--g--color-black), transparent 15%) 86.41%,
    color-mix(in srgb, var(--g--color-black), transparent 8.2%) 89.8%,
    color-mix(in srgb, var(--g--color-black), transparent 3.5%) 93.2%,
    color-mix(in srgb, var(--g--color-black), transparent 0.8%) 96.6%,
    var(--g--color-black) 100%
  );

  transition: opacity 0.4s ease;

  .root[data-active='true'] & {
    opacity: 0.4;
    transition: opacity 0.4s ease 0.4s;
  }
}

.bottomFade {
  pointer-events: none;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 40%;

  background-image: linear-gradient(
    180deg,
    color-mix(in srgb, var(--g--color-black), transparent 100%) 49.02%,
    color-mix(in srgb, var(--g--color-black), transparent 99.2%) 52.42%,
    color-mix(in srgb, var(--g--color-black), transparent 96.5%) 55.82%,
    color-mix(in srgb, var(--g--color-black), transparent 91.8%) 59.22%,
    color-mix(in srgb, var(--g--color-black), transparent 85%) 62.62%,
    color-mix(in srgb, var(--g--color-black), transparent 77%) 66.02%,
    color-mix(in srgb, var(--g--color-black), transparent 66.7%) 69.41%,
    color-mix(in srgb, var(--g--color-black), transparent 55.7%) 72.81%,
    color-mix(in srgb, var(--g--color-black), transparent 44.3%) 76.21%,
    color-mix(in srgb, var(--g--color-black), transparent 33.3%) 79.61%,
    color-mix(in srgb, var(--g--color-black), transparent 23%) 83.01%,
    color-mix(in srgb, var(--g--color-black), transparent 15%) 86.41%,
    color-mix(in srgb, var(--g--color-black), transparent 8.2%) 89.8%,
    color-mix(in srgb, var(--g--color-black), transparent 3.5%) 93.2%,
    color-mix(in srgb, var(--g--color-black), transparent 0.8%) 96.6%,
    var(--g--color-black) 100%
  );
}

.container {
  position: absolute;
  top: 0;

  /*
  If we have recently played items, we need to account for the height of the
  recently played items section:
  - The Recently played title height: calc(18px * 1.2).
  - Gap: var(--g--margin-md).
  - The Recently played cards height: var(--g--card-landscape-large-height).
  - Gap: var(--slider--nav-bottom-padding).
  */

  /* prettier-ignore */
  bottom: calc(
    (18px * 1.2) +
    var(--g--margin-md) +
    var(--g--card-landscape-large-height) +
    var(--slider--nav-bottom-padding)
  );
  left: var(--g--margin-viewport);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--margin-md);
  align-items: flex-start;
  justify-content: center;

  width: 420px;
  min-width: 320px;
  max-width: 50%;

  /*
  When there are NO recently played items we lift up the trending cards row up
  so that the top half of the card is visible in viewport. We need to account
  for that here:
  - The trending cards title height: calc(52px * 1.2).
  - Gap: var(--g--margin-md).
  - The trending card height divided by two: calc(var(--g--card-portrait-large-height) / 2).
  */
  :global(html.noRecentlyPlayedItems) & {
    /* prettier-ignore */
    bottom: calc(
      (52px * 1.2) +
      var(--g--margin-md) +
      (var(--g--card-portrait-large-height) / 2)
    );
  }
}

.topContent {
  position: relative;
  transform: translateY(calc(var(--g--margin-md) * -1)) scale(0.9);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--margin-md);

  width: 100%;

  opacity: 0;
  filter: blur(8px);

  transition:
    transform var(--g--anim-duration) var(--g--anim-easing),
    opacity var(--g--anim-duration) linear,
    filter var(--g--anim-duration) linear;

  .root[data-active='true'] & {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
    transition:
      transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s,
      opacity 0.4s linear 0.4s,
      filter 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  }
}

.heading {
  position: relative;
  margin: 0;
}

.logo {
  display: block;

  max-width: 100%;
  max-height: 16vh;
  margin-right: auto;

  object-fit: contain;
  object-position: left center;
}

.bottomContent {
  position: relative;
  transform: translateY(calc(var(--g--margin-md) * -1)) scale(0.9);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--margin-md);

  width: 100%;

  opacity: 0;
  filter: blur(8px);

  transition:
    transform var(--g--anim-duration) var(--g--anim-easing) 0.1s,
    opacity var(--g--anim-duration) linear 0.1s,
    filter var(--g--anim-duration) linear 0.1s;

  .root[data-active='true'] & {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
    transition:
      transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s,
      opacity 0.5s linear 0.3s,
      filter 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  }
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;

  font-size: max(17px, 1.5vh);
  line-height: 1.25;
  color: var(--g--color-white);
  text-shadow: 2px 2px 4px var(--g--glass-dark-900);
  word-break: break-word;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--g--card-action-gap);

  width: 100%;
  max-width: 240px;
}

.launchAction {
  flex-grow: 1;
  flex-shrink: 1;
}

.infoAction {
  flex-grow: 0;
  flex-shrink: 0;
}
