.root {
  --slider-card--actions-top-margin: calc(var(--g--card-gap) / 2);
  --slider-card--edge-padding: var(--g--card-gap);
  --slider-card--border-radius: var(--g--card-border-radius);
  --slider-card--gap: var(--g--card-gap);
  --slider-card--action-gap: var(--g--card-action-gap);
  --slider-card--action-height: var(--g--card-action-height);
  --slider-card--title-height: calc(14px + (2 * var(--g--margin-sm)));
  --slider-card--width: var(--g--card-landscape-large-width);
  --slider-card--height: var(--g--card-landscape-large-height);
  --slider-card--container-width: calc(
    var(--slider-card--width) + 2 * var(--slider-card--edge-padding)
  );
  --slider-card--container-height: calc(
    var(--slider-card--height) + var(--slider-card--title-height) + var(--slider-card--edge-padding)
  );
  --slider-card--width-unitless: var(--g--card-landscape-large-width-unitless);
  --slider-card--active-scale-factor: calc(
    (var(--slider-card--width-unitless) + 16) / var(--slider-card--width-unitless)
  );

  pointer-events: none;
  content-visibility: auto;
  contain-intrinsic-size: var(--slider-card--container-width) var(--slider-card--container-height);

  position: relative;

  contain: strict;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  width: var(--slider-card--container-width);
  height: var(--slider-card--container-height);
  margin: calc(var(--slider-card--edge-padding) * -1);
  margin-bottom: 0;

  &.isActive,
  &.isAnimatingOut {
    content-visibility: visible;
    contain: layout;
  }
}

.scrollXTarget {
  position: absolute;
  inset: var(--slider-card--edge-padding) var(--slider-card--edge-padding) 0;
  visibility: hidden;
}

.title {
  pointer-events: auto;

  position: absolute;
  bottom: 0;
  left: var(--slider-card--edge-padding);

  overflow: hidden;
  display: block;

  width: var(--slider-card--width);
  height: var(--slider-card--title-height);

  font-size: 14px;
  font-weight: 500;
  line-height: var(--slider-card--title-height);
  color: var(--g--color-grey);
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    opacity: 0;
    transition-duration: calc(var(--g--anim-duration) / 2);
  }
}

.imageContainer {
  position: absolute;
  top: var(--slider-card--edge-padding);
  left: var(--slider-card--edge-padding);
  transform: scale(1);

  width: var(--slider-card--width);
  height: var(--slider-card--height);

  border-radius: var(--slider-card--border-radius);
  box-shadow: var(--g--shadow-md);

  transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  .isActive & {
    transform: scale(var(--slider-card--active-scale-factor));
  }
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(var(--slider-card--action-height)) scale(0.9);

  display: flex;
  gap: var(--slider-card--action-gap);

  width: 100%;
  height: calc(var(--slider-card--action-height) + var(--slider-card--actions-top-margin));
  padding-top: var(--slider-card--actions-top-margin);

  opacity: 0;

  transition:
    opacity var(--g--anim-duration) var(--g--anim-easing),
    transform var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    pointer-events: auto;
    transform: translateY(100%) scale(1);
    opacity: 1;
    transition:
      opacity calc(var(--g--anim-duration) * 1.5) var(--g--anim-easing),
      transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);
  }
}

.launchAction {
  flex-grow: 1;
  flex-shrink: 1;
}

.infoAction {
  flex-grow: 0;
  flex-shrink: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: var(--slider-card--border-radius);
}

.imageLink {
  pointer-events: auto;

  position: absolute;
  inset: 0;

  color: transparent;

  background: transparent;
  border-radius: var(--slider-card--border-radius);
  outline: none;
  box-shadow: var(--g--inset-highlight-md);
}

.activeTitle {
  position: absolute;
  bottom: 6px;
  left: 6px;

  overflow: hidden;
  display: block;

  max-width: calc(100% - 12px);
  height: 26px;
  padding: 0 6px;

  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: var(--g--color-white);
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 0;
  background: var(--g--glass-dark-100);
  backdrop-filter: blur(20px);
  border-radius: var(--g--border-radius-sm);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    opacity: 1;
  }
}
