.root {
  --trending-card-list--header-height: calc(52px * 1.2);
  --trending-card-list--scroller-padding-top: var(--g--margin-md);
  --trending-card-list--scroller-padding-bottom: 80px;
  --trending-card-list--scroller-indicator-bottom: calc(100% + 3px);
  --trending-card-list--card-height: var(--g--card-portrait-large-height);
  --trending-card-list--height: calc(
    var(--trending-card-list--header-height) + var(--trending-card-list--scroller-padding-top) +
      var(--trending-card-list--scroller-padding-bottom) + var(--trending-card-list--card-height)
  );

  content-visibility: auto;
  contain-intrinsic-size: 100% var(--trending-card-list--height);

  position: relative;

  contain: strict;

  height: var(--trending-card-list--height);

  /*
  If there are no recently played items let's lift the container enough to show
  half a card row in the bottom of the slider. This code asssumes that the
  slider is right above the container.
  */
  :global(html.noRecentlyPlayedItems) & {
    margin-top: calc(
      (
          var(--g--margin-xl) + var(--trending-card-list--header-height) +
            var(--trending-card-list--scroller-padding-top) +
            (var(--trending-card-list--card-height) / 2)
        ) *
        -1
    );
  }
}

.scrollToTarget {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.header {
  z-index: 1;
  top: 4px;
}

.scroller.scroller {
  --card-scroller--padding-top: var(--trending-card-list--scroller-padding-top);
  --card-scroller--padding-bottom: var(--trending-card-list--scroller-padding-bottom);
  --card-scroller--indicator-bottom: var(--trending-card-list--scroller-indicator-bottom);
}
