.root {
  --featured-categories--normal-card-active-scale: 1; /* Computed with JS */
  --featured-categories--wide-card-active-scale: 1; /* Computed with JS */

  position: relative;

  display: grid;
  grid-template-columns: 1fr auto; /* Two equal columns */
  grid-template-rows: auto auto auto; /* Three rows */
  gap: var(--g--margin-md);

  margin: 0 var(--g--margin-viewport);
}

.title {
  position: relative;

  grid-column: 1 / span 2; /* Span both columns */
  grid-row: 1;

  margin: 0;
  padding: 0;

  font-size: 52px;
  font-weight: 300;
  line-height: 1.2;
  text-shadow: 0 1px 2px var(--g--glass-dark-100);
  letter-spacing: -0.5px;
  word-break: break-word;
}

.description {
  grid-column: 1;
  grid-row: 2;

  max-width: 600px;
  margin: 0;

  font-size: 19px;
}

.link {
  grid-column: 2;
  grid-row: 2;
}

.cards {
  position: relative;

  container-type: inline-size;
  display: flex;
  grid-column: 1 / span 2; /* Span both columns */
  grid-row: 3;
  flex-flow: row wrap;
  gap: var(--g--card-gap);
  place-content: space-between space-between;
  align-items: flex-start;
}

.card {
  position: relative;

  contain: layout style;
  display: block;

  width: calc(40% - var(--g--card-gap) / 2);
  height: clamp(300px, 39.1cqw, 500px);

  border-radius: var(--g--card-border-radius);
  outline: 0;

  &.isWideCard {
    width: calc(60% - var(--g--card-gap) / 2);
  }
}

.cardContent {
  position: relative;
  transform: scale(1);

  contain: paint style;
  display: block;

  width: 100%;
  height: 100%;

  border-radius: inherit;
  box-shadow: var(--g--shadow-md);

  transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  html[data-nav-device='keyboard'] .card:focus-within & {
    outline: 1px solid var(--g--color-white);
    outline-offset: 3px;
  }

  html[data-nav-device='mouse'] .card:hover &,
  html[data-nav-device='keyboard'] .card:focus-within & {
    transform: scale(var(--featured-categories--normal-card-active-scale));
  }

  html[data-nav-device='mouse'] .card.isWideCard:hover &,
  html[data-nav-device='keyboard'] .card.isWideCard:focus-within & {
    transform: scale(var(--featured-categories--wide-card-active-scale));
  }
}

.cardImageContainer {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  container-type: size;
  display: block;

  width: 100%;
  height: 100%;

  border-radius: inherit;
}

.cardLandscapeImage,
.cardPortraitImage {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: inherit;

  &.cardPortraitImage {
    display: none;
  }

  @container (orientation: portrait) {
    &.cardLandscapeImage {
      display: none;
    }

    &.cardPortraitImage {
      display: block;
    }
  }
}

.cardFade {
  pointer-events: none;

  position: absolute;
  bottom: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 65%;

  opacity: 0.5;
  background: linear-gradient(
    0deg,
    var(--g--color-black) 0%,
    color-mix(in srgb, var(--g--color-black), transparent 100%) 100%
  );
  border-radius: inherit;
}

.cardTitle {
  pointer-events: none;

  position: absolute;
  right: var(--g--margin-md);
  bottom: calc(var(--g--margin-md) - (4cqw * 0.1));
  left: var(--g--margin-md);

  display: block;

  font-family: var(--g--font-condensed);
  font-size: 4cqw;
  font-weight: 700;
  font-style: normal;
  line-height: 1.2;
  color: var(--g--color-white);
  text-transform: uppercase;
  word-break: break-word;
}

.cardHighlight {
  pointer-events: none;

  position: absolute;
  inset: 0;

  display: block;

  color: transparent;

  background: transparent;
  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-md);
}

@media screen and (width >= 1024px) {
  .root {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: max(30%, 400px) auto;
    grid-template-rows: auto auto 1fr;
    gap: var(--g--margin-md) var(--g--margin-xl);
    align-items: start;
  }

  .title {
    grid-column: 1;
    grid-row: 1;
    margin: 0;
  }

  .description {
    grid-column: 1;
    grid-row: 2;
    margin: 0;
  }

  .link {
    grid-column: 1;
    grid-row: 3;
    justify-self: baseline;
    margin: 0;
  }

  .cards {
    grid-column: 2;
    grid-row: 1 / span 3; /* Span across all rows */
    margin: 0;
  }
}
