.root {
  --collection--height: 700px;
  --collection--card-height: 164px;

  content-visibility: auto;
  contain-intrinsic-size: 100% var(--collection--height);

  position: relative;

  contain: strict;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--g--card-gap);
  align-items: flex-end;
  justify-content: space-between;

  height: var(--collection--height);
  padding: 0 var(--g--margin-viewport);

  &.flipColumns {
    flex-direction: row-reverse;
  }
}

.imageColumn {
  position: relative;
  flex-shrink: 0;
  width: calc(40% - var(--g--card-gap) / 2);
  height: var(--collection--height);
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;

  container-type: size;

  width: 100%;
  height: 100%;

  opacity: 0;
  border-radius: var(--g--card-border-radius);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  /* Show first card's image when no cards are active */
  html[data-nav-device='mouse'] .root:not(:has(.card:hover)) &:nth-child(1),
  html[data-nav-device='keyboard'] .root:not(:has(.card:focus-within)) &:nth-child(1),
  /* Show the first card's image when it is active */
  html[data-nav-device='mouse'] .root:has(.card:nth-child(1):hover) &:nth-child(1),
  html[data-nav-device='keyboard'] .root:has(.card:nth-child(1):focus-within) &:nth-child(1),
  /* Show the second card's image when it is active */
  html[data-nav-device='mouse'] .root:has(.card:nth-child(2):hover) &:nth-child(2),
  html[data-nav-device='keyboard'] .root:has(.card:nth-child(2):focus-within) &:nth-child(2),
  /* Show the third card's image when it is active */
  html[data-nav-device='mouse'] .root:has(.card:nth-child(3):hover) &:nth-child(3),
  html[data-nav-device='keyboard'] .root:has(.card:nth-child(3):focus-within) &:nth-child(3) {
    opacity: 1;
  }
}

.portraitImage,
.landscapeImage {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: inherit;

  &.portraitImage {
    display: none;
  }

  @container (orientation: portrait) {
    &.landscapeImage {
      display: none;
    }

    &.portraitImage {
      display: block;
    }
  }
}

.imageHighlight {
  position: absolute;
  inset: 0;

  background: transparent;
  border-radius: var(--g--card-border-radius);
  box-shadow: var(--g--inset-highlight-md);
}

.contentColumn {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  justify-content: flex-end;

  width: calc(60% - var(--g--card-gap) / 2);
  height: var(--collection--height);
  padding-left: calc(var(--g--card-gap) + 1px);
}

.header {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  height: 100%;
  min-height: 0;
}

.headerTopSpacer,
.headerBottomSpacer {
  width: 100%;
  height: 100%;
  min-height: 0;
}

.headerBottomSpacer {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    left: calc((var(--g--card-gap) + 1px) * -1);

    display: block;

    width: 1px;
    height: 100%;

    background-color: color-mix(in srgb, var(--g--glass-light-100), transparent 30%);
  }
}

.headerAnnotation {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 var(--g--margin-sm) 0;
}

.headerAnnotationIcon {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(0px - 6px - var(--g--card-gap) - 0.5px);

  width: 12px;
  height: 12px;

  color: var(--g--color-ray-orange);
}

.headerAnnotationText {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--g--color-grey);
}

.headerTitle {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    left: calc((var(--g--card-gap) + 1px) * -1);

    display: block;

    width: 1px;
    height: 100%;

    background-color: color-mix(in srgb, var(--g--glass-light-100), transparent 30%);
  }
}

.headerTitleText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;

  font-size: 52px;
  font-weight: 300;
  line-height: 1.2;
  color: var(--g--color-white);
  letter-spacing: -0.5px;
  word-break: break-word;
}

.cards {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  gap: calc(var(--g--card-gap) / 2);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    left: calc((var(--g--card-gap) + 1px) * -1);

    display: block;

    width: 1px;
    height: 100%;

    background-color: color-mix(in srgb, var(--g--glass-light-100), transparent 30%);
  }
}

.card {
  position: relative;
}

.activeCardIndicator {
  position: absolute;
  top: 0;
  left: 0;
  left: calc((var(--g--card-gap) + 1.5px) * -1);

  overflow: hidden;
  display: block;

  width: 2px;
  height: 100%;

  & > div {
    position: relative;
    top: calc(var(--collection--card-height) * -1);
    transform: translateY(0);

    width: 100%;
    height: var(--collection--card-height);

    opacity: 0;
    background-color: var(--g--color-ray-orange);

    transition:
      transform var(--g--anim-duration) var(--g--anim-easing),
      opacity var(--g--anim-duration) var(--g--anim-easing);

    html[data-nav-device='mouse'] .root:has(.card:nth-child(1):hover) &,
    html[data-nav-device='keyboard'] .root:has(.card:nth-child(1):focus-within) & {
      transform: translateY(var(--collection--card-height));
      opacity: 1;
    }

    html[data-nav-device='mouse'] .root:has(.card:nth-child(2):hover) &,
    html[data-nav-device='keyboard'] .root:has(.card:nth-child(2):focus-within) & {
      transform: translateY(
        calc((var(--collection--card-height) * 2) + (calc(var(--g--card-gap) / 2)))
      );
      opacity: 1;
    }

    html[data-nav-device='mouse'] .root:has(.card:nth-child(3):hover) &,
    html[data-nav-device='keyboard'] .root:has(.card:nth-child(3):focus-within) & {
      transform: translateY(calc((var(--collection--card-height) * 3) + var(--g--card-gap)));
      opacity: 1;
    }
  }
}

@media screen and (width <= 1400px) {
  .imageContainer {
    right: auto;
    bottom: auto;
    width: calc(100% + var(--g--margin-viewport));
    height: 100%;

    .root:not(.flipColumns) & {
      margin-left: calc(var(--g--margin-viewport) * -1);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .root.flipColumns & {
      margin-right: calc(var(--g--margin-viewport) * -1);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .imageHighlight {
    right: auto;
    bottom: auto;
    width: calc(100% + var(--g--margin-viewport));
    height: 100%;

    .root:not(.flipColumns) & {
      margin-left: calc(var(--g--margin-viewport) * -1);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .root.flipColumns & {
      margin-right: calc(var(--g--margin-viewport) * -1);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@media screen and (width <= 1023px) {
  .root {
    /*
    NB: This was measured manually from the DOM, but we could also compute
    this value dynamically (but it would be quite a bit more complex). When we
    change the layout set this temporarily to auto and measure the height again.
    The reason we can't leave this to auto is that the height is used to set
    the intrinsic size of the component and auto would cause the component to
    render with a height of 0.
     */
    --collection--height: 633.4px;

    gap: 0;
  }

  .imageColumn {
    display: none;
  }

  .contentColumn {
    width: 100%;
  }

  .headerTopSpacer,
  .headerBottomSpacer {
    display: none;
  }

  .headerTitle {
    padding-bottom: var(--g--margin-md);
  }
}
