.root {
  contain-intrinsic-size: 100% var(--collection--card-height);

  position: relative;

  contain: strict;
  display: flex;
  flex-direction: row nowrap;
  gap: var(--g--margin-md);

  width: 100%;
  height: var(--collection--card-height);
  padding: var(--g--margin-sm);

  background-color: var(--g--glass-dark-100);
  border-radius: calc(var(--g--card-border-radius) + var(--g--margin-sm));

  transition: background-color var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] &:hover,
  html[data-nav-device='keyboard'] &:focus-within {
    background-color: color-mix(in srgb, var(--g--glass-light-100), transparent 60%);
  }
}

.imageContainer {
  position: relative;

  flex-grow: 0;
  flex-shrink: 0;

  aspect-ratio: 16 / 9;
  min-width: 0;
  height: 100%;

  text-decoration: none;

  border-radius: var(--g--card-border-radius);
  outline: 0;
  box-shadow: var(--g--shadow-sm);
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: inherit;
}

.imageHighlight {
  position: absolute;
  inset: 0;

  background: transparent;
  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-md);
}

.content {
  position: relative;
  transform: translateY(calc((var(--g--card-action-height) / 2) + (var(--g--margin-sm) / 2)));

  container-type: inline-size;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  gap: var(--g--margin-sm);
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  min-width: 0;

  transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    transform: translateY(0);
  }
}

.title {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  gap: var(--g--margin-sm);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 0;
}

.titleText {
  position: relative;

  overflow: hidden;
  display: -webkit-box;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;

  min-width: 0;

  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--g--color-white);
  letter-spacing: -0.25px;
  word-break: break-word;
}

.category {
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 1;

  max-width: 40%;
  margin-right: var(--g--margin-sm);

  text-overflow: ellipsis;

  @container (width <= 360px) {
    & {
      display: none;
    }
  }
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;

  max-width: 420px;
  margin: 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--g--color-grey);
  word-break: break-word;
}

.actions {
  pointer-events: none;

  transform: scale(0.9);

  display: inline-flex;
  gap: var(--g--card-action-gap);

  opacity: 0;

  transition:
    opacity calc(var(--g--anim-duration) / 2) var(--g--anim-easing),
    transform calc(var(--g--anim-duration) / 2) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
    transition:
      opacity var(--g--anim-duration) var(--g--anim-easing) calc(var(--g--anim-duration) / 2),
      transform var(--g--anim-duration) var(--g--anim-bounce-easing)
        calc(var(--g--anim-duration) / 2);
  }
}
