.root {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.scroller.scroller {
  --card-scroller--padding-bottom: var(--slider--nav-bottom-padding);
  --card-scroller--indicator-bottom: calc(100% + 3px);
}
