.root {
  --games-callout--width: calc(100% - (var(--g--margin-viewport) * 2));
  --games-callout--height: 600px;
  --games-callout--bg-width: min(1500px, 100%);

  content-visibility: auto;
  contain-intrinsic-size: var(--games-callout--width) var(--games-callout--height);

  position: relative;

  contain: strict;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: var(--games-callout--width);
  height: var(--games-callout--height);
  margin: 0 var(--g--margin-viewport);

  background-color: var(--g--color-black);
  border-radius: var(--g--border-radius-md);
  box-shadow: var(--g--shadow-md);
}

.bg {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: calc(50%);
  transform: translateX(-50%);

  display: block;

  width: var(--games-callout--bg-width);
  height: 100%;

  border-radius: inherit;

  & > .image {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
    border-radius: inherit;
  }
}

.bgEdgeFade {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  inset: 0;

  background: linear-gradient(
    to right,
    var(--g--color-black) 0%,
    transparent 10%,
    transparent 90%,
    var(--g--color-black) 100%
  );
  border-radius: inherit;
}

.bgLeft {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: calc((100% - 650px) / 2);
  height: 100%;

  border-radius: inherit;

  & > .image {
    position: absolute;
    top: 0;
    right: 0;

    display: block;

    width: 100%;
    max-width: 600px;
    height: 100%;

    object-fit: cover;
    object-position: right center;
    border-radius: inherit;
  }
}

.bgRight {
  pointer-events: none;

  position: absolute;
  top: 0;
  right: 0;

  display: block;

  width: calc((100% - 650px) / 2);
  height: 100%;

  border-radius: inherit;

  & > .image {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    max-width: 462px;
    height: 100%;

    object-fit: cover;
    object-position: left center;
    border-radius: inherit;
  }
}

.title {
  position: relative;

  margin: 0 0 var(--g--margin-xl);

  font-size: min(5vw, 52px);
  font-weight: 300;
  line-height: 1.2;
  color: var(--g--color-white);
  text-align: center;
  letter-spacing: -0.5px;
}

.topHighlight {
  pointer-events: none;
  content: '';

  position: absolute;
  z-index: 2;
  inset: 0;

  display: block;

  border-radius: var(--g--border-radius-md);
  box-shadow: var(--g--inset-highlight-md);
}

@media (width <= 1100px) {
  .root {
    --games-callout--height: min(600px, 60vw);

    align-items: flex-start;
    padding: var(--g--margin-xl);
  }

  .bgLeft {
    display: none;
  }

  .bgRight {
    width: calc(100% - min(450px, 45vw));
  }

  .title {
    max-width: min(400px, 50vw);
    margin-bottom: var(--g--margin-md);
    text-align: left;
  }
}

@media (width <= 600px) {
  .root {
    padding: var(--g--margin-md);
  }
}
