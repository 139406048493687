.root {
  --focus-scroll-padding: 0px;
  --slider--nav-bottom-padding: 72px;

  /*
  A computed min-height which accounts for the slider elements when there are
  recently played items:
  - Gap: var(--g--margin-md).
  - The Featured text block height: calc(18px * 1.2).
  - Gap: var(--g--margin-md).
  - The logo max height: 16vh.
  - Gap: var(--g--margin-md).
  - The description text block max height: 64px.
  - Gap: var(--g--margin-md).
  - The featured game actions block height: 34px.
  - Gap: var(--g--margin-md).
  - The Recently played text block height: calc(18px * 1.2).
  - Gap: var(--g--margin-md).
  - The Recently played title height: var(--g--card-landscape-large-height).
  - Gap: var(--slider--nav-bottom-padding).
  */

  /* prettier-ignore */
  --slider--min-height: calc(
    var(--g--margin-md) +
    (18px * 1.2) +
    var(--g--margin-md) +
    16vh +
    var(--g--margin-md) +
    64px +
    var(--g--margin-md) +
    34px +
    var(--g--margin-md) +
    (18px * 1.2) +
    var(--g--margin-md) +
    var(--g--card-landscape-large-height) +
    var(--slider--nav-bottom-padding)
  );

  --slider--height: max(var(--g--100vh), var(--slider--min-height));

  content-visibility: auto;
  contain-intrinsic-size: 100% var(--slider--height);

  position: relative;

  contain: strict;

  height: var(--slider--height);

  background-color: var(--g--color-black);

  /*
  A computed min-height which accounts for the slider elements when there are
  NO recently played items. In such case we lift the trending card row up so
  that the top half of the card is visible in viewport:
  - Gap: var(--g--margin-md).
  - The Featured text block height: calc(18px * 1.2).
  - Gap: var(--g--margin-md).
  - The logo max height: 16vh.
  - Gap: var(--g--margin-md).
  - The description text block max height: 64px.
  - Gap: var(--g--margin-md).
  - The featured game actions block height: 34px.
  - Gap: var(--g--margin-md).
  - The trending cards title height: calc(52px * 1.2).
  - Gap: var(--g--margin-md).
  - The trending card height divided by two: calc(var(--g--card-portrait-large-height) / 2).
  */
  :global(html.noRecentlyPlayedItems) & {
    /* prettier-ignore */
    --slider--min-height: calc(
      var(--g--margin-md) +
      (18px * 1.2) +
      var(--g--margin-md) +
      16vh + var(--g--margin-md) +
      64px +
      var(--g--margin-md) +
      34px +
      var(--g--margin-md) +
      (52px * 1.2) +
      var(--g--margin-md) +
      (var(--g--card-portrait-large-height) / 2)
    );
  }
}
